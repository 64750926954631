body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fc .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc-toolbar {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.fc-toolbar-title {
  font-size: 1.2em !important;
  color: #9d9d9d;
}

.fc-prev-button,
.fc-next-button {
  color: #8ca1af !important;
  background-color: transparent !important;
  border-color: transparent !important;
  outline: none !important;
}

.fc-toolbar-ltr {
  padding: 0 0 0 0;
  @media screen and (max-width: 480px) {
    padding: 0 100px 0 100px;
  }
}

.date-legends-parent {
  padding-left: 100px;
  @media screen and (max-width: 480px) {
    padding-left: 0px;
  }
}

.calendar-wrapper {
  padding: 0px 100px 0px 100px;
  @media screen and (max-width: 480px) {
    padding: 0 0 0 0;
  }
}
